import React from "react";
import styled from "styled-components";
import ArrowButton from "../arrows/ArrowButton";


const HeaderComponent = styled.div`
  height: 10vh;
  width: 100vw;
  justify-content: center;
  display: inline-flex;
  justify-content: space-around;
`;

const Title = styled.div`
  text-align: center;
  font-size: clamp(1rem, 3vw + 0.5rem, 5vh);
  color: #373737;
  height: 10vh;
  width: 30vw;
`;

function ArrowHeader(props) {
    return (
        <HeaderComponent>
            <ArrowButton direction="left" to={props.leftPath}></ArrowButton>
            <Title>{props.title}</Title>
            <ArrowButton direction="right" to={props.rightPath}></ArrowButton>
        </HeaderComponent>
    );
  }
  
  export default ArrowHeader;