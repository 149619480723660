import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import UnstyledLink from '../utils/UnstyledLink';
import { styled } from '@mui/system';

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    color: theme.checkBoxColor,
    '& .MuiTypography-root': {
      color: theme.checkBoxColor,
      fontFamily: 'Space Mono, monospace'
    }
  }));


function getCheckBoxLabelColor(termError, formColor, errorColor) {
    if(termError){
      return errorColor
    }
    return formColor
  }

function getCheckBox(termAgreement, setTermAgreement, termError, formColor, errorColor) {
    var theme = {checkBoxColor: getCheckBoxLabelColor(termError, formColor, errorColor)}
    return (
      <CustomFormControlLabel
        theme = {theme}
        label= {
          <div>
            <span>I agree to the terms of our </span>
            <UnstyledLink to={'/privacy'}><u>Privacy Policy</u></UnstyledLink>
          </div>
        }
        control={
          <Checkbox
            style={{ color: theme.checkBoxColor }}
            checked={termAgreement}
            onChange={(e) => setTermAgreement(!termAgreement)}
          />
        }
      />
    )
  }

export default getCheckBox;