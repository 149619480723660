import React, { useRef, useEffect }  from "react";
import * as d3 from "d3";


function getAxisArray(rate){
    const lowerElement = rate -(rate % 10);
    return [lowerElement + 20, 100];
}

function getMinDimension() {
    return Math.min(window.innerWidth, window.innerHeight);
}

function getRadiusInPx(radius) {
    return (getMinDimension() * radius/100);
}


const ProportionalCircle = (props) => {
    const circle = useRef();

    let dimensions = {
        width: "50vw",
        height: "50vh",
        cx: "50%",
        cy: "50%",
    };

    const sqrtScale = d3.scaleSqrt()
        .domain([0, 100])
        .range([0, 24])

    const svg = d3
        .select(circle.current)
        .classed("circle-svg", true)
        .attr("width", dimensions.width)
        .attr("height", dimensions.height)
  
    useEffect(() => {
        if (props.data.co2 === undefined) return;
        const absorptionRate = parseFloat(props.data.co2.absorptionRate);
        const emissionRate = parseFloat(props.data.co2.emissionRate);
        let displayRate;
        let circleColor;
        let axisColor;
        if (props.data.runningDelta === null){
            displayRate = 5;
            circleColor = '#949292';
            axisColor = '#949292';
        }
        else if (absorptionRate > emissionRate) {
            displayRate = absorptionRate;
            circleColor = '#A8C256';
            axisColor = '#373737';
        }
        else {
            displayRate = emissionRate;
            circleColor = '#F65E5E';
            axisColor = '#373737';
        }
        
        const axis = getAxisArray(displayRate);

        // append the axis circles
        svg.append('g')
            .attr('class', 'axis-wrap')
            .selectAll('circle')
            .data(axis)
            .enter().append('circle')
            .attr('class', d => 'axis axis-' + d)
            .attr('r', d => `${getRadiusInPx(sqrtScale(d)).toString()}px`)
            .attr('cx', dimensions.cx)
            .attr('cy', dimensions.cy)
            .style('fill', 'none')
            .style('stroke', axisColor)
            .style('opacity', 0.5)
        
        // append the circle 
        svg.append('circle')
            .attr('class', 'area-circle')
            .attr('cx', dimensions.cx)
            .attr('cy', dimensions.cy)
            .style('fill', circleColor)
            .style('opacity', 0.9)
            .attr('r', `${getRadiusInPx(sqrtScale(displayRate)).toString()}px`)

        if (props.data.runningDelta !== null) {
            // append some axis labels
            svg.append('g')
                .attr('class', 'axis-labels-wrap')
                .selectAll('.axis-labels')
                .data(axis)
                .enter().append('text')
                .attr('x', "50%")
                .attr('y', d => `${
                    (window.innerHeight/4 + getRadiusInPx(sqrtScale(d)) - 5).toString()
                }px`)
                .style('text-anchor', 'middle')
                .style('fill', '#373737')
                .attr('font-size', '0.8rem')
                .text(d => d + '%')
            // append it's value
            svg.append('text')
                .attr('class', 'area-label')
                .attr('x', dimensions.cx)
                .attr('y', dimensions.cy)
                .style('text-anchor', 'middle')
                .style('font-weight', 'bold')
                //.style('font-size', `${sqrtScale(displayRate)-20}vmin`)//'4vmin')
                .attr('font-size', '1rem')
                .style('fill', '#373737')
                .text(displayRate + '%')
        }
        return () => {
            svg.selectAll('*').remove();
        } 
    }, [
        props.data.co2,
        props.data.runningDelta,
        dimensions.cx,
        dimensions.cy,
        sqrtScale,
        svg
    ]);
    return (
        <svg ref={circle}/>
    )

}

export default ProportionalCircle;