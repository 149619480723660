import React from "react";
import styled from 'styled-components';
import PageTitle from "../../components/PageSEO";

import Presentation from "./Presentation";
import Service from "./Service";
import Reviews from "./Reviews";
import FAQ from "./FAQ";
import Subscription from "./Subscription";


const LandingPageComponent = styled.div`
    width: 100vw;
    display: flex;
    flex-direction: column;
    font-size: clamp(0.5rem, 2vw + 0.5rem, 1.7rem);
    font-family: Space Mono, monospace;
`;

function BluGreenCloud() {
    return (
        <LandingPageComponent>
            <PageTitle
                title="BluGreen"
                description="Carbon capture at your fingertips."
                canonical="/blugreen"
            />
            <Presentation/>
            <Service/>
            <Reviews/>
            <FAQ/>
            <Subscription/>
        </LandingPageComponent>
    );
}

export default BluGreenCloud;
