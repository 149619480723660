import Privacy from "./pages/privacy/Privacy";
import Algae from "./pages/algae/Algae";
import BluGreenCloud from "./pages/blugreencloud/BluGreenCloud";
import BaseSuccessPage from "./pages/token/BaseSuccessPage";
import BaseFailedPage from "./pages/token/BaseFailedPage";
import Authentication from "./pages/authentication/Authentication";
import ControlCentre from "./pages/experience/ControlCentre";
import ControlCentreLive from "./pages/experience/ControlCentreLive";
import ControlCentreStats from "./pages/experience/ControlCentreStats";
import ExperienceResult from "./pages/experience/ExperienceResult";
import PageNotFound from './components/PageNotFound';
import PrivateRouteWrapper from './pages/authentication/PrivateRouteWrapper';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
/*
import MatomoTracker from "./components/matomo/MatomoTracker";
<MatomoTracker />
*/

function App() {
  return (
    <div>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/privacy" element={<Privacy/>} />
            <Route path="/" element={<BluGreenCloud/>} />
            <Route path="/experience/authentication/:date" element={<Authentication/>} />
            <Route path="/experience/control-center/:date" element={
              <PrivateRouteWrapper>
                <ControlCentre/>
              </PrivateRouteWrapper>
            } />
            <Route path="/experience/control-center/:date/stats" element={
              <PrivateRouteWrapper>
                <ControlCentreStats/>
              </PrivateRouteWrapper>
            } />
            <Route path="/experience/control-center/:date/live" element={
              <PrivateRouteWrapper>
                <ControlCentreLive/>
              </PrivateRouteWrapper>
            } />
            <Route path="/experience/result/:date" element={<ExperienceResult/>} />
            <Route path="/experience/access-denied" element={<BaseFailedPage message="This token is not valid anymore."/>} />
            <Route path="/subscription/confirmation" element={<BaseSuccessPage message="Thank you for subscribing"/>} />
            <Route path="/subscription/unsubscribe" element={<BaseFailedPage message="Sorry to see you go"/>} />
            <Route path="/subscription/invalid" element={<BaseFailedPage message="This token is invalid"/>} />
            <Route path="/old" element={<Algae/>}/>
            <Route path="/blugreencloud" element={<Navigate to="/" replace />} />
            <Route path='*' element={<PageNotFound/>} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
