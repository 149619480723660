import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import ButtonHomebrew from '../../components/buttons/ButtonHomebrew';

const ClockForm = () => {
  const { date } = useParams();
  const [clockSize, setClockSize] = useState(0);
  const [selectedSlices , setSelectedSlices] = useState(new Array(12).fill(false));
  const [scheduledSlices, setScheduledSlices] = useState(new Array(12).fill(false));

  const createSlicePath = (cx, cy, r, startAngle, endAngle) => {
    const x1 = cx + r * Math.sin(Math.PI * startAngle / 180);
    const y1 = cy - r * Math.cos(Math.PI * startAngle / 180);
    const x2 = cx + r * Math.sin(Math.PI * endAngle / 180);
    const y2 = cy - r * Math.cos(Math.PI * endAngle / 180);
    const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;

    return `M ${cx},${cy} L ${x1},${y1} A ${r},${r} 0 ${largeArcFlag},1 ${x2},${y2} Z`;
  };

  const fontSize = clockSize * 0.03;
  const circleRadius = (clockSize - fontSize * 4) / 2;

  const updateSelectedSlices = (index) => {
   setSelectedSlices(prevSelectedSlices => {
    const updatedSelectedSlices = [...prevSelectedSlices];
     updatedSelectedSlices[index] = !prevSelectedSlices[index];
     return updatedSelectedSlices;
    });
  };

  const handleSliceClick = (index) => {
    updateSelectedSlices(index);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(selectedSlices);
    const formData = {
        date: date,
        slots: selectedSlices.map((selected, index) => {
            return {
            status: selected ? "SEALED" : "UNSEALED",
            startMinute: index * 5,
            endMinute: index * 5 + 5,
            }
        })
    };
    console.log(formData);
    axios.post(
        'https://kimon.blugreen.cloud/algae/day-schedule',
        formData
    ).then( response => {
        console.log(response)
        setScheduledSlices(selectedSlices.slice());
    }).catch(error => {
        console.log(error)
    })
  };

  const slices = [
    { startAngle: 0, endAngle: 30 },
    { startAngle: 30, endAngle: 60 },
    { startAngle: 60, endAngle: 90 },
    { startAngle: 90, endAngle: 120 },
    { startAngle: 120, endAngle: 150 },
    { startAngle: 150, endAngle: 180 },
    { startAngle: 180, endAngle: 210 },
    { startAngle: 210, endAngle: 240 },
    { startAngle: 240, endAngle: 270 },
    { startAngle: 270, endAngle: 300 },
    { startAngle: 300, endAngle: 330 },
    { startAngle: 330, endAngle: 360 }
  ];

  useEffect(() => {
    const handleResize = () => {
      const parentWidth = document.querySelector('.parent').offsetWidth;
      setClockSize(Math.min(parentWidth, window.innerHeight) * 0.7);
    };

    handleResize(); // set initial size on mount
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    axios.get(`https://kimon.blugreen.cloud/algae/day-schedule/${date}`)
      .then(response => {
        const fetchedData = response.data;
        const newSelectedSlices = fetchedData.slots.map(slot => slot.status === 'SEALED');
        setSelectedSlices(newSelectedSlices);
        setScheduledSlices(newSelectedSlices.slice());
      })
      .catch(error => {
        console.log(error);
      });
  }, [date]);

  const hasChanges = JSON.stringify(selectedSlices) !== JSON.stringify(scheduledSlices);

  return (
    <div className="parent">
        <form onSubmit={handleSubmit}>
            <Wrapper>
              <Content>
                <Clock width={clockSize} height={clockSize}>
                  {slices.map((slice, index) => (
                    <>
                      <Slice
                        key={index}
                        d={createSlicePath(
                          clockSize * 1 / 2,
                          clockSize * 1 / 2,
                          circleRadius,
                          slice.startAngle,
                          slice.endAngle
                        )}
                        selected={selectedSlices[index]}
                        onClick={() => handleSliceClick(index)}
                      />
                      <MinuteLabel
                        x={clockSize / 2 + (circleRadius + fontSize * 2 / 3) * Math.sin(Math.PI * slice.startAngle / 180)}
                        y={clockSize / 2 - (circleRadius + fontSize * 2 / 3) * Math.cos(Math.PI * slice.startAngle / 180) + (fontSize * 0.3)}
                        fontSize={fontSize}
                        text={index * 5}
                      />
                    </>
                  ))}
                </Clock>
                <Legend>
                <LegendItem>
                  <LegendColor color="#80CED7" />
                  <span>Unsealed</span>
                </LegendItem>
                <LegendItem>
                  <LegendColor color="#373737" />
                  <span>Sealed</span>
                </LegendItem>
              </Legend>
                <ButtonWrapper>
                    {hasChanges && (
                      <ButtonHomebrew
                      type="submit"
                      text="Submit"
                      color="#a8c256"/>
                    )}
                </ButtonWrapper>
              </Content>
            </Wrapper>
        </form>
    </div>
  );
};

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90vh;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  margin-bottom: 5vh;
`;

const Clock = styled.svg``;

const MinuteLabel = ({ x, y, fontSize, text }) => (
  <text x={x} y={y} textAnchor="middle" fontSize={fontSize} fill="#373737">
    {text}
  </text>
);

const glowAnimation = `
  @keyframes glow {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.98);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const Slice = styled.path`
  fill: ${props => props.selected ? '#373737': '#80CED7'};
  ${glowAnimation}
  animation: glow 2s ease-in-out infinite;
  transform-origin: center;
  &:hover {
    cursor: pointer;
  }
`;

const Legend = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1vw;
`;

const LegendColor = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  background-color: ${props => props.color};
  border-radius: 50%;
  margin-right: 0.625rem;
`;

export default ClockForm;