import {useState, useEffect} from 'react'
import axios from 'axios'


function useDataHook(url, sec=2000) {
    const [data, setData] = useState({})
    const [seconds, setSeconds] = useState(0)
    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(seconds + 1)
        }, sec)
        return () => clearInterval(interval)
    })
    useEffect(() => {
        axios.get(url)
            .then(res => {
                setData(res.data)
            })
    }, [url, seconds])
    return data
}

export default useDataHook