import React, { useState } from 'react';
import styled from 'styled-components';

const QAContainer = styled.div`
    background-color: #C6ECEE;
    box-shadow: 0.5vw 0.5vh 1vw rgba(0, 0, 0, 0.2);
    margin-bottom: 5vh;
    padding: 2vh 2vw;
    transition: all 0.3s ease;
    margin-left: auto;
    margin-right: auto;
`;

const Question = styled.div`
    font-size: clamp(1rem, 1.5vw + 0.5rem, 2.5vh);
    cursor: pointer;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Answer = styled.div`
    font-size: clamp(1rem, 1vw + 0.2rem, 2vh);
    display: ${props => props.isOpen ? 'block' : 'none'};
    padding-top: 2vh;
`;

const Arrow = styled.span`
    margin-left: 5vw;
    transform: ${props => props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
    transition: transform 0.2s ease;
`;



const QuestionAnswer = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <QAContainer>
            <Question onClick={() => setIsOpen(!isOpen)}>
                {question}
                <Arrow>{isOpen ? '↓' : '→'}</Arrow>
            </Question>
            <Answer isOpen={isOpen}>{answer}</Answer>
        </QAContainer>
    );
};

export default QuestionAnswer;
