import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';


const CustomTextField = styled(TextField)(({ theme }) => ({
    input: {
        color: theme.formColor,
        fontFamily: 'Space Mono, monospace'
    },
    '& label': {
        color: theme.formColor,
        fontFamily: 'Space Mono, monospace'
    },
    '& label.Mui-focused': {
        color: theme.formColor,
    },
    '& label.Mui-error': {
      color: theme.errorColor,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.formColor,
      },
      '&:hover fieldset': {
        borderColor: theme.formColor,
      },
      '&.Mui-focused fieldset': {
        borderColor:theme.formColor,
      },
      '&.Mui-error fieldset': {
        borderColor: theme.errorColor,
        color: theme.errorColor
      }
    },
  }));


function getCustomTextField(setEmail, emailError, formColor, errorColor) {
    var theme = {formColor: formColor, errorColor: errorColor}
    return (
        <CustomTextField
        onChange={(e) => setEmail(e.target.value)}
        label="Email"
        variant="outlined"
        required
        error={emailError}
        theme={theme}/>
    )
}

export default getCustomTextField;
