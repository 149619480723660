import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

const StyledButton = styled.button`
  display: inline-block;
  font-size: 1rem;
  padding: 0.5em 1em;
  text-decoration: none;
  background: ${props => props.color || '#F65E5E'};
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-family: 'Space Mono', monospace;
  transition: background 0.3s;

  &:hover {
    background: ${props => darken(0.1, props.color || '#F65E5E')};
  }

  &:disabled {
    background: #ddd;
    color: #666;
    cursor: not-allowed;
  }
`;

function ButtonHomebrew({ type, text, color }) {
  return <StyledButton type={type} color={color}>{text}</StyledButton>;
}

export default ButtonHomebrew;
