import React, { useState } from 'react';
import styled from 'styled-components';
import { validate } from 'react-email-validator';
import axios from 'axios';
import UnstyledLink from '../utils/UnstyledLink';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EmailInput = styled.input`
  border: 2px solid ${props => props.isValid ? 'black' : '#F65E5E'};
  border-radius: 0;
  padding: 2vh 2vw;
  width: 75%;
  position: relative;
`;

const CheckboxContainer = styled.div`
  display: flex;
  width: 80%;
  align-items: center;
  color: ${props => props.checkboxValid ? 'black' : '#F65E5E'};
  margin-top: 2vh;
  font-size: clamp(0.8rem, 0.75vw + 0.2rem, 1vh);
`;


const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  height: 5vh;
  margin-right: 1vw;
  border: 2px solid ${props => props.isChecked ? 'transparent' : '#F65E5E'};
  accent-color: black;  // Set the color of the checkbox

  &:checked {
    background-color: black;
  }
`;


const SubscribeButton = styled.button`
  background-color: #F71735;
  color: white;
  border: none;
  border-radius: 0;
  padding: 2vh 4vw;
  margin-top: 2vh;
  cursor: pointer;
  font-weight: bold; /* Make text bold */
`;

const SubscriptionMessageStyled = styled.p`
  color: green;
`;

function SubscriptionForm(props) {
    const project = props.project
    const subscribedTag = project + '-subscribed'
    const [email, setEmail] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [emailValid, setEmailValid] = useState(true);
    const [checkboxValid, setCheckboxValid] = useState(true);
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      const isValidEmail = validate(email);
      setEmailValid(isValidEmail);
      const isCheckboxValid = isChecked;
      setCheckboxValid(isCheckboxValid);
  
      if (isValidEmail && isChecked) {
        try {
          const response = await axios.post(
            'https://kimon.blugreen.cloud/algae/subscribe',
            { email, project }
        );
          console.log(response);
          setIsSubmitted(true);
          sessionStorage.setItem('subscribed', true);
        } catch (error) {
          console.error(error);
        }
        sessionStorage.setItem(subscribedTag, true)
      }
    };
  
    if (isSubmitted || sessionStorage.getItem(subscribedTag) === 'true') {
      return <SubscriptionMessageStyled>Thanks for subscribing!</SubscriptionMessageStyled>;
    }
  
    return (
        <FormContainer>
          <EmailInput type="email" onChange={e => setEmail(e.target.value)} isValid={emailValid} placeholder="Email*" />
          <CheckboxContainer checkboxValid={checkboxValid}>
            <StyledCheckbox
              type="checkbox"
              onChange={() => setIsChecked(!isChecked)}
              isChecked={isChecked}
            />
            <div>
                <span>I agree with </span>
                <UnstyledLink to={'/privacy'}><u>privacy policy</u></UnstyledLink>
            </div>
          </CheckboxContainer>
          <SubscribeButton onClick={handleSubmit}>Subscribe</SubscribeButton>
        </FormContainer>
      );
  }
  
  export default SubscriptionForm;
  