import React from "react";
import styled from 'styled-components';

const ActionButtonStyle = styled.button`
    background-color: ${props => props.backgroundColor};
    border: none;
    color: ${props => props.textColor};
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: Space Mono, monospace;
    &:hover {
        background-color: ${props => props.hoverBackgroundColor};
        color: ${props => props.hoverTextColor};
    }
`;

function ActionButton(props) {
    return (
        <ActionButtonStyle
            onClick={props.onClick}
            backgroundColor={props.backgroundColor}
            textColor={props.textColor}
            hoverBackgroundColor={props.hoverBackgroundColor}
            hoverTextColor={props.hoverTextColor}
        >
            {props.text}
        </ActionButtonStyle>
    );
}

export default ActionButton;
