import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Cookies from "js-cookie";

function PrivateRouteWrapper({ children }) {
  const { date } = useParams()
  const location = useLocation();
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const sessionId = Cookies.get("session_id");
    if (sessionId) {
        fetch(`https://kimon.blugreen.cloud/algae/auth/session/${date}`, {
            headers: { Authorization: `Bearer ${sessionId}` }
        })
        .then((response) => {
          if (response.ok) {
            setAuthenticated(true);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [location, date]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!authenticated) {
    return navigate("/experience/access-denied", {"replace": true});
  }

  return children;
}

export default PrivateRouteWrapper;
