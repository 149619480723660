import React from 'react';
import styled from 'styled-components';

const StyledOptOut = styled.iframe`
    border: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    height: 15vh;
    width: 100vw;
`;

function OptOutPrivacyBanner() {
    return (
        <div>
            <StyledOptOut component='iframe'
            src="https://matomo.blugreen.cloud/index.php?module=CoreAdminHome&action=optOut&language=en&backgroundColor=000000&fontColor=ffffff&fontSize=&fontFamily=Roboto%2C%20sans-serif"
            />

        </div>
    );
}

export default OptOutPrivacyBanner;