import styled from 'styled-components';
import { Link } from 'react-router-dom';

const UnstyledLink = styled(Link)`
    color: inherit;
    text-decoration: none;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;

export default UnstyledLink;
