import React from "react";
import styled from 'styled-components';
import useDataHook from '../../components/data/DataHook';
import LineChart from '../../components/data/LineChart';
import ScrollAnimation from '../../components/ScrollAnimation';


const BluGreenCloudChartComponent = styled.div`
    height: 100vh;
    background: #fffbfc;
`;//background: linear-gradient(to bottom, #dbe9ee, #FFFFFF 25%); 

const ChartCanvas = styled.div`
    height: 50vh;
    width: 90vw;
    margin-top: 10vh;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 25vh;
`;

const TitleComponent = styled.div`
    text-align: center;
    font-size: clamp(1rem, 3vw + 0.5rem, 5vh);
    color: #373737;
`;

function BluGreenCloudChart() {
  let averagesURL = 'https://kimon.blugreen.cloud/algae/in-chamber-co2-averages';
  let roundsURL = 'https://kimon.blugreen.cloud/algae/rounds';
  var averages = useDataHook(averagesURL, 120000)
  var rounds = useDataHook(roundsURL, 120000)
  return (
    <BluGreenCloudChartComponent>
      <ScrollAnimation
                    duration={1000}
                    dataAOS={"fade-up"}
                >
        <TitleComponent>
          <h2>Recent stats</h2>
        </TitleComponent>
          <ChartCanvas>
            <LineChart 
              averages={averages}
              rounds={rounds}
            />
          </ChartCanvas>
      </ScrollAnimation>
    </BluGreenCloudChartComponent>
  );
}

export default BluGreenCloudChart;