import React from 'react';
import styled from 'styled-components';
import Card from "../../components/zigzag/Card";
import zigZagImage1 from '../../images/blugreen/zig_zag_1.png';
import zigZagImage4 from '../../images/blugreen/zig_zag_4.png';
import zigZagImage2 from '../../images/blugreen/zig_zag_2.png';


const ServiceStyled = styled.div`
    width: 100vw;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
    padding-top: 10vh;
    padding-bottom: 10vh;
`;

function Service() {
    return (
        <ServiceStyled>
            <Card 
                imageLeft={false}
                title="How does Carbon Capture look like?"
                text="Humanity's challenge ahead demands increased carbon capture. Yet, very few have a real-time view of this crucial process."
                imageUrl={zigZagImage1}
            />
            <Card 
                imageLeft={true}
                title="Gaming to the Rescue"
                text="While some see carbon capture as a service, we believe it can be transformed into an engaging experience. Through gaming, we bring a real life bioreactor experience right to your fingertips." 
                imageUrl={zigZagImage4}
            />
            <Card 
                imageLeft={false}
                title="Join the Movement"
                text="Join our community and get a carbon capture experience for free! Experience the real time carbon capture journey and help shape the future with your feedback." 
                imageUrl={zigZagImage2}
            />
        </ServiceStyled>
    );
}

export default Service;
