import React from 'react';
import styled from 'styled-components';

const CardsContainer = styled.div`
    display: flex;
    background-color: #C6ECEE;
    margin: 15vh 15vw;
    padding: 5vh 5vw;
    box-shadow: 0.5vw 0.5vh 1vw rgba(0, 0, 0, 0.2);

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const Card = styled.div`
    flex: 1;
    min-height: 15vh;
    padding: 1.5vh 1vw;
    border-right: 0.1vw solid black;

    &:last-child {
        border-right: none;
    }

    @media (max-width: 768px) {
        border-right: none;
        border-bottom: 0.1vw solid black;

        &:last-child {
            border-bottom: none;
        }
    }
`;

const ReviewText = styled.p`
    font-size: clamp(1rem, 1vw + 0.2rem, 2vh);
`;

const AuthorName = styled.p`
    font-size: clamp(1rem, 1.5vw + 0.5rem, 2.5vh);
    font-weight: bold;
    margin-top: 1vh;
    align-self: flex-end;
`;


const Cards = ({ reviews }) => {
    return (
        <CardsContainer>
            {reviews.map((review, index) => (
                <Card key={index}>
                    <ReviewText>{review.text}</ReviewText>
                    <AuthorName>{review.author}</AuthorName>
                </Card>
            ))}
        </CardsContainer>
    );
};

export default Cards;
