import { useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';


function Authentication() {
    const { date } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const token = urlSearchParams.get('token');
        if (token) {
            fetch(`https://kimon.blugreen.cloud/algae/auth/token/${date}`, {
                headers: { Authorization: `Bearer ${token}` }
            })
            .then((response) => {
                if (response.ok){
                    response.json().then(data => {
                        const expirationDate = new Date(data.expiresAt);
                        Cookies.set(
                            'session_id',
                            data.sessionId,
                            { expires: expirationDate}
                        );
                        navigate(`/experience/control-center/${date}`, {"replace": true})
                    })
                } else {
                    console.info("Response not ok: " + response.status)
                    navigate("/experience/access-denied", {"replace": true})
                }
            })
            .catch(error => {
                console.info("Error: " + error)
                navigate("/experience/access-denied", {"replace": true})
            });
        }

    }, [navigate, date]);
    return null
}

export default Authentication;
