import React from 'react';
import styled from 'styled-components';

const CardContainer = styled.div`
    display: flex;
    flex-direction: ${props => props.imageLeft ? 'row-reverse' : 'row'};
    align-items: center;
    justify-content: center;
    margin: 5vh 15vw;
    padding: 2vh 0;
    height: 25vh; // Fixed height for larger screens
    overflow: hidden;

    @media (max-width: 768px) {
        flex-direction: column;
        height: auto;
`;

const CardText = styled.div`
    flex: 1;
    padding: 1.5vh;
    overflow-y: auto;

    @media (max-width: 768px) {
        overflow-y: visible;
    }
`;

const CardImage = styled.img`
    flex: 1;
    width: 20vh;
    height: 30vh;
    object-fit: contain;
    border-radius: 5px;
`;

const CardTitle = styled.h2`
    font-size: clamp(1rem, 2.5vw + 0.5rem, 3vh);
    margin-bottom: 1vh;
    color: #333;
`;

const CardBody = styled.p`
    font-size: clamp(1rem, 1.5vw + 0.5rem, 2.5vh);
    color: #666;
`;


const Card = ({ imageLeft, title, text, imageUrl }) => {
    return (
        <CardContainer imageLeft={imageLeft}>
            <CardText imageLeft={imageLeft}>
                <CardTitle>{title}</CardTitle>
                <CardBody>{text}</CardBody>
            </CardText>
            <CardImage src={imageUrl} imageLeft={imageLeft} />
        </CardContainer>
    );
};


export default Card;
