import React, { useRef, useEffect } from "react";
import styled from 'styled-components';


const StyledRoundDetails = styled.div`
    height: 15vh;
    width: 25wv;
    text-align: center;
    background: #FFFBFC;
    font-size: 1rem;
`;


function RoundDetails(props) {
    const details = useRef();
    useEffect(() => {
        if ((props.data.runningDelta === undefined) && (props.data.nextRoundDelta === undefined)) return;
        if ((props.data.runningDelta === null) && (props.data.nextRoundDelta === null)) return; 
        if (props.data.runningDelta === null) {
            details.current.innerHTML = `
                <g style="font-size:1.5rem;">Waiting for the next round</g> <br>
                Next sealed round is in <br />
                ${props.data.nextRoundDelta.hours}h:${props.data.nextRoundDelta.minutes}m:${props.data.nextRoundDelta.seconds}s`;
        }
        else{
            details.current.innerHTML = `
                <g style="color:#A8C256;font-size:1.5rem;"><b>Live</b></g> <br>
                Chamber has been sealed for <br />
                ${props.data.runningDelta.hours}h:${props.data.runningDelta.minutes}m:${props.data.runningDelta.seconds}s`;
        }
    }, [props.data.runningDelta, props.data.nextRoundDelta]);
    return (
        <StyledRoundDetails >
                <div ref={details} />
        </StyledRoundDetails>
    );
}

export default RoundDetails;