import React from 'react';
import styled from 'styled-components';

const VideoWrapper = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
`;


const VideoBackground = styled.video`
    position: relative;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
    object-fit: cover;
`;

const Title = styled.h1`
    position: absolute;
    top: 20vh;
    left: 5vw;
    font-size: clamp(1rem, 6vw + 0.5rem, 8vh);
    color: #C6ECEE;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const Subtitle = styled.h2`
    position: absolute;
    top: 40vh;
    left: 5vw;
    font-size: clamp(1rem, 3vw + 0.5rem, 4vh);
    color: #ACEE4D;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

function Presentation() {
    return (
        <div>
            <VideoWrapper>
                <VideoBackground autoPlay loop muted playsInline poster="/spirulina_video_poster.png">
                    <source src="/istockphoto-1295945706-640_adpp_is.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </VideoBackground>
            </VideoWrapper>
            <Title>BluGreenCloud</Title>
            <Subtitle>Carbon capture at your fingertips</Subtitle>
        </div>
    );
}

export default Presentation;
