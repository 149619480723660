import React from 'react';
import styled from 'styled-components';

import SmarthPhoneAlgaeImage from '../../images/algae/smartphone_algae_image_medium.png';
import ScrollAnimation from '../../components/ScrollAnimation';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';



const ValueComponent = styled.div`
    min-height: 100vh;
    background: linear-gradient(to bottom, #80CED7 20%, #80CED7 80%, #373737 95%);
`; //linear-gradient(to bottom, #a8c256, #FFFBFC 40%);


const TitleComponent = styled.div`
    text-align: center;
    font-size: clamp(1rem, 3vw + 0.5rem, 5vh);
    color: #373737;
`;

const ValueInnerContentComponent = styled.div`
    width: 80vw;    
    min-height: 70vh;
    margin-top: 5vh;
    margin-left: 10vw;
    margin-bottom: 10vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: space-around;
`;

const ValueTextContentComponent = styled.div`
    max-width: min(531px, 100%);
    text-align: justify;
    margin-bottom: 5vh;
    font-size: clamp(0.5rem, 3vw + 0.5rem, 1.5rem);
`;

const ValueMediaContentComponent = styled.div`
    --width: min(512px, 100%);
    width: min(27rem, 100%);
    margin-bottom: 10vh;
    padding 4vmin;
`;



function Value() {
    return (
        <ValueComponent>
            <ScrollAnimation
                    duration={1000}
                    dataAOS={"fade-up"}
                >
                <TitleComponent>
                    <h2>Carbon capture at your fingertips</h2>
                </TitleComponent>
                <ValueInnerContentComponent>
                    <ValueTextContentComponent>
                        BluGreenCloud gives you full transparency on the carbon capture process. Our goal is to put you in charge of that process, by giving you remote control of a bioreactor. Your objective is simple, maximize the CO<sub>2</sub> captured. Are you ready for the challenge?
                    </ValueTextContentComponent>
                    <ValueMediaContentComponent>
                        <Card>
                            <CardMedia
                                component="img"
                                image={SmarthPhoneAlgaeImage}
                            />
                        </Card>
                    </ValueMediaContentComponent>
                </ValueInnerContentComponent>
            </ScrollAnimation>
        </ValueComponent>
    );
}

export default Value;