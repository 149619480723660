import React, { useEffect } from 'react';
import Aos from "aos";
import "aos/dist/aos.css"


function ScrollAnimation(props) {
    useEffect(() => {
        Aos.init({ duration: props.duration});
    }, [props.duration]);
    return (
        <div  data-aos={props.dataAOS}>
            { props.children }
        </div>
    );
}

export default ScrollAnimation;