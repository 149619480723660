import React from 'react';

import styled, { keyframes } from 'styled-components';
import RandomInt from '../utils/RandomInt';


const StyledFloatingBackground = styled.div`
    height: 80%;
    width: 100%;
`;

const Circle = styled.div.attrs(props => ({
}))`
    top: 0;
    left: 0;
    background: #FFFBFC;
    border-radius: 50vmin;
    width: ${props => props.size}vw;
    height: ${props => props.size}vw;
`;

const float = keyframes`
    0% {
        opacity: 0;
        transform: translateY(0);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(-80vh);
    }
`;

const FloatUp = styled.div.attrs(props => ({
}))`
    display: inline-block;
    animation: ${float} ${props => props.animationTime} ease-in infinite forwards;
    margin-left: ${props => props.marginLeft};
`; // padding: 2rem; margin-top: 30vh;

const FloatingBackground = (props) => {
    return (
        <StyledFloatingBackground>
            {props.children}
            <FloatUp 
                marginLeft="5vw"
                animationTime="10s"
            >
                <Circle size={RandomInt(1, 4)}/>
            </FloatUp>
            <FloatUp 
                marginLeft="20vw"
                animationTime="5s"
            >
                <Circle size={RandomInt(1, 4)}/>
            </FloatUp>
            <FloatUp 
                marginLeft="20vw"
                animationTime="7s"
            >
                <Circle size={RandomInt(1, 3)}/>
            </FloatUp>
            <FloatUp 
                marginLeft="20vw"
                animationTime="3s"
            >
                <Circle size={RandomInt(1, 3)}/>
            </FloatUp>
            <FloatUp 
                marginLeft="20vw"
                animationTime="13s"
            >
                <Circle size={RandomInt(1, 3)}/>
            </FloatUp>
        </StyledFloatingBackground>
    )
}

export default FloatingBackground;
