import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { darken } from 'polished';

const ButtonStyled = styled(Link)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background: #F65E5E;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.2s;
  font-size: 1.5rem;
  font-family: 'Space Mono', monospace;
  text-decoration: none; // removes underline

  &:hover {
    background: ${darken(0.1, '#F65E5E')};
  }

  &:active {
    background: ${darken(0.2, '#F65E5E')};
  }
`;

const ArrowButton = ({ direction, to }) => {
  const arrow = direction === "left" ? "<" : ">";

  return (
    <ButtonStyled to={to}>
      {arrow}
    </ButtonStyled>
  );
};

export default ArrowButton;
