import React from "react";
import styled from 'styled-components';
import FloatingBackground from "../../components/background/FloatingBackground";
import ActionButton from "../../components/selfstyled/buttons/ActionButton";

const FailedPageComponent = styled.div`
    height: 100vh;
    background: #373737;
    font-family: Space Mono, monospace;
`;

const ContentComponent = styled.div`
    padding-top: 30vh;
    height: 40vh;
    padding-bottom: 15vh;
    text-align: center;
    font-size: clamp(1rem, 3vw + 0.5rem, 5vh);
    color: #FFFBFC;
    line-height: 10vh;
`;



function BaseFailedPage(props) {
    return (
        <FailedPageComponent>
            <FloatingBackground>
                <ContentComponent>
                    <p>
                        {props.message}
                    </p>
                    <p>
                        <ActionButton
                            text="Home" onClick={() => window.location.href = "/"}
                            backgroundColor="#FFFBFC"
                            textColor="#373737"
                            hoverBackgroundColor="#4c4b4b"
                            hoverTextColor="#FFFBFC"
                        />
                    </p>
                </ContentComponent>
            </FloatingBackground>
        </FailedPageComponent>
    );
}

export default BaseFailedPage;