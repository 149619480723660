import React from "react";
import styled from 'styled-components';
import useDataHook from '../../components/data/DataHook';
import ProportionalCircle from '../../components/data/ProportionalCircle';
import RoundDetails from './RoundDetails';
import CircleLegend from '../../components/data/CircleLegend';
import ScrollAnimation from '../../components/ScrollAnimation';


const BluGreenCloudNowComponent = styled.div`
    height: 100vh;
    background: #FFFBFC;
`;

const DataCanvas = styled.div`
    height: 50vh;
    width: 75vw;
    padding-left: 25vw;
`;

const TitleComponent = styled.div`
    text-align: center;
    font-size: clamp(1rem, 3vw + 0.5rem, 5vh);
    color: #373737;
`;

function BluGreenCloudNow() {
  var co2Details = useDataHook('https://kimon.blugreen.cloud/algae/last-round-co2-details')
  return (
    <BluGreenCloudNowComponent>
      <ScrollAnimation
                    duration={1000}
                    dataAOS={"fade-up"}
                >
        <TitleComponent>
          <h2>Live sealed round</h2>
        </TitleComponent>
        <RoundDetails data={co2Details}/>
        <DataCanvas>
          <ProportionalCircle data={co2Details}/>
        </DataCanvas>
        <CircleLegend data={co2Details}/>      
      </ScrollAnimation>
    </BluGreenCloudNowComponent>
  );
}

export default BluGreenCloudNow;