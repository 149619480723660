import React from "react";
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import ArrowHeader from "../../components/headers/ArrowHeader";
import useDataHook from '../../components/data/DataHook';
import ProportionalCircle from '../../components/data/ProportionalCircle';
import RoundDetails from '../algae/RoundDetails';
import CircleLegend from '../../components/data/CircleLegend';


const ControlCentreComponent = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #FFFBFC;
    font-family: Space Mono, monospace;
`;

const DataCanvas = styled.div`
    height: 50vh;
    width: 75vw;
    padding-left: 25vw;
`;


function ControlCentreLive() {
    var co2Details = useDataHook('https://kimon.blugreen.cloud/algae/last-round-co2-details')
    const { date } = useParams()
    const rightPath = `/experience/control-center/${date}`;
    const leftPath = `/experience/control-center/${date}/stats`;
    return (
        <ControlCentreComponent>
            <ArrowHeader
                rightPath={rightPath}
                leftPath={leftPath}
                title="Live sealed round"
            />
            <RoundDetails data={co2Details}/>
            <DataCanvas>
                <ProportionalCircle data={co2Details}/>
            </DataCanvas>
            <CircleLegend data={co2Details}/>
        </ControlCentreComponent>
    );
}

export default ControlCentreLive;
