import React from 'react';
import styled from 'styled-components';
import FloatingBackground from "../../components/background/FloatingBackground";
import ButtonHomebrew from '../../components/buttons/ButtonHomebrew';
import { Link } from 'react-scroll';


const PresentationComponent = styled.div`
    height: 100vh;
    background: linear-gradient(to bottom, #80CED7 20%, #80CED7 80%, #a8c256 95%);
`;

const TitleComponent = styled.div`
    padding-top: 30vh;
    height: 40vh;
    padding-bottom: 15vh;
    text-align: center;
    font-size: clamp(1rem, 3vw + 0.5rem, 5vh);
    color: #FFFBFC;
    line-height: 10vh;
`; //#FFFBFC #373737


function Presentation(props) {
    return (
        <PresentationComponent>
            <FloatingBackground>
                <TitleComponent>
                    <h1>CO<sub>2</sub> made visible</h1>
                    <h3>To fight it, we need to see it.</h3>
                    <Link to="serviceComponent" smooth={true} duration={500}>
                        <ButtonHomebrew
                            type="button"
                            text="Learn More"
                            color="#a8c256"                            
                        />
                    </Link>
                </TitleComponent>
            </FloatingBackground>
        </PresentationComponent>
    );
}

export default Presentation;