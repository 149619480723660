import React from 'react';
import styled from 'styled-components';
import OptOutPrivacyBanner from "../../components/matomo/OptOutPrivacyBanner";
import UnstyledLink from "../../components/utils/UnstyledLink";

const PrivacyComponent = styled.div`
    display: flex;
    flex-direction: column;
    background: #373737;
    background-image: radial-gradient(#FFFBFC 0.12vh, transparent 0);
    background-size: 5vh 5vh;
`;

const PolicyContentComponent = styled.div`
    margin-left: 10vw;
    margin-right: 10vw;
    text-align: justify;
    color: #FFFBFC;
    font-family: Space Mono, monospace;
    background: #373737;
`;

const StyledListItem = styled.li`
    margin: 2vh;
`;

const FooterSpace = styled.div`
    height: 20vh;
`;

const PolicySection = styled.div`
    margin-top: 5vh;
`; 

const TableComponent = styled.table`
    width: 80vw;
    border-collapse: collapse;
`;

const TableElementComponent = styled.td`
    border: 0.2vh solid;
    padding-top: 3vh;
    padding-bottom: 3vh;
    text-align: left;
`;




function Privacy() {
    return (
        <PrivacyComponent>
            <PolicyContentComponent>
                <PolicySection>
                    <h1>Privacy Policy</h1>
                    <p>
                        This Policy describes the information we collect from you, how we use that information and our legal basis for doing so. It also covers whether and how that information may be shared and your rights and choices regarding the information you provide to us. This Privacy Policy applies to the information that we obtain through your use of <u><UnstyledLink to='https://blugreen.cloud'>blugreen.cloud</UnstyledLink></u> website.
                    </p>
                </PolicySection>
                <PolicySection>
                    <h2>What We Collect and Receive</h2>
                    <p>
                        In order for us to provide you the best possible experience on our websites, we need to collect and process certain information. Depending on your use of the Services, that may include:
                        <ul>
                            <StyledListItem>
                                <b>Subscription</b> — when you sign up on our website, we ask you to provide us with information such email address. As otherwise detailed in this Privacy Policy, we will solely process this information to provide you with the service you signed up for.
                            </StyledListItem>
                            <StyledListItem>
                                <b>Usage data</b> — when you visit our site, we will store: the website from which you visited us from, the parts of our site you visit, the date and duration of your visit, your anonymised IP address, information from the device (device type, operating system, screen resolution, language, country you are located in, and web browser type) you used during your visit, and more. We process this usage data in Matomo Analytics for statistical purposes, to improve our site and to recognize and stop any misuse.
                            </StyledListItem>
                            <StyledListItem>
                                <b>Contact us via email</b> — for example, when you ask for support, send us questions or comments, or report a problem, we will collect your name, email address, message, etc. We use this data solely in connection with answering the  queries we receive.
                            </StyledListItem>
                            <StyledListItem>
                                <b>Cookies</b> —we use cookies (small data files transferred onto computers or devices by sites) for record-keeping purposes and to enhance functionality on our site. You may deactivate or restrict the transmission of cookies by changing the settings of your web browser. Cookies that are already stored may be deleted at any time.
                            </StyledListItem>
                        </ul>
                    </p>
                </PolicySection>
                <PolicySection>
                    <h2>Opt-out of website tracking</h2>
                    <p>
                        You can opt out of being tracked by our analytics instance below:
                    </p>
                    <p style={{'margin-left': '2vw'}}>
                        Opt-out complete; your visits to this website will not be recorded by the Web Analytics tool. Note that if you clear your cookies, delete the opt-out cookie, or if you change computers or Web browsers, you will need to perform the opt-out procedure again.
                    </p>
                </PolicySection>
                <PolicySection>
                    <h2>Your Rights</h2>
                    <p>
                        You have the right to be informed of Personal Data processed by Suricatta, a right to rectification/correction, erasure and restriction of processing. You also have the right to ask from us a structured, common and machine-readable format of Personal Data you provided to us. We can only identify you via your email address and we can only adhere to your request and provide information if we have Personal Data about you through you having made contact with us directly and/or you using our site and/or service. We cannot provide, rectify or delete any data that we store on behalf of our users or customers. To exercise any of the rights mentioned in this Privacy Policy and/or in the event of questions or comments relating to the use of Personal Data you may contact us at <u><UnstyledLink to='mailto:info@blugreen.cloud'>info@blugreen.cloud</UnstyledLink></u>. In addition, you have the right to lodge a complaint with the data protection authority in your jurisdiction.
                    </p>
                </PolicySection>
                <PolicySection>
                    <h2>Subprocessors</h2>
                    <p>
                        We use a select number of trusted external service providers for certain technical data processing and/or service offerings. These service providers are carefully selected and meet high data protection and security standards. We only share information with them that is required for the services offered and we contractually bind them to keep any information we share with them as confidential and to process Personal Data only according to our instructions. Suricatta uses the following subprocessors to process the data collected by our websites:
                    </p>
                    <TableComponent>
                        <tbody>
                            <tr>
                                <TableElementComponent><b>Subprocessor</b></TableElementComponent>
                                <TableElementComponent><b>Data location and security</b></TableElementComponent>
                                <TableElementComponent><b>Service</b></TableElementComponent>
                            </tr>
                            <tr>
                                <TableElementComponent><u><UnstyledLink to='https://aws.amazon.com/'>Amazon Web Services</UnstyledLink></u></TableElementComponent>
                                <TableElementComponent>Europe, Ireland</TableElementComponent>
                                <TableElementComponent>Cloud and Storage</TableElementComponent>
                            </tr>
                        </tbody>
                    </TableComponent>
                </PolicySection>
                <PolicySection>
                    <h2>Third party services we use</h2>
                    <p>When you visit our websites we use the following third party services which may collect personal data:</p>
                    <TableComponent>
                        <tbody>
                            <tr>
                                <TableElementComponent><b>Recipient</b></TableElementComponent>
                                <TableElementComponent><b>Purpose of processing</b></TableElementComponent>
                                <TableElementComponent><b>Lawful basis</b></TableElementComponent>
                                <TableElementComponent><b>Data location and security</b></TableElementComponent>
                                <TableElementComponent><b>Personal data collected by the third party</b></TableElementComponent>
                                <TableElementComponent><b>Privacy policy</b></TableElementComponent>
                            </tr>
                            <tr>
                                <TableElementComponent><u><UnstyledLink to='https://matomo.org/matomo-on-premise'>Matomo on Premise (self-hosted)</UnstyledLink></u></TableElementComponent>
                                <TableElementComponent>To collect and analyse information about how you interact with our websites (web analytics), and to recognize and stop any misuse</TableElementComponent>
                                <TableElementComponent>Legitimate interest</TableElementComponent>
                                <TableElementComponent>Ireland</TableElementComponent>
                                <TableElementComponent>Anonymised IP address, Pages visited, browser and device used, mouse movements, anonymised key strokes, and <u><UnstyledLink to="https://matomo.org/faq/general/faq_18254/">more</UnstyledLink></u>.</TableElementComponent>
                                <TableElementComponent><u><UnstyledLink to="https://matomo.org/privacy-policy/">link</UnstyledLink></u></TableElementComponent>
                            </tr>
                        </tbody>
                    </TableComponent>
                </PolicySection>
                <PolicySection>
                    <h2>Retention of data</h2>
                    <p>
                         We will retain and use this information as necessary for the purposes set out in this Policy and to the extent necessary to comply with our legal obligations, resolve disputes, enforce our agreements and protect Suricatta's legal rights. We also collect and maintain aggregated, anonymized or pseudonymized information which we may retain indefinitely to protect the safety and security of our Site, improve our Services or comply with legal obligations.
                    </p>
                </PolicySection>
                <PolicySection>
                    <h2>Privacy Policy Changes</h2>
                    <p>
                        We may update this Policy from time to time. If we do, we'll let you know about any material changes, either by notifying you on the website or by sending you an email.
                    </p>
                </PolicySection>
                <PolicySection>
                    <h2>
                        Contact Us
                    </h2>
                    <p>
                        If you have any questions or concerns regarding your information, please contact <u><UnstyledLink to= "mailto:info@blugreen.cloud">info@blugreen.cloud</UnstyledLink></u>.
                    </p>
                </PolicySection>
                <PolicySection>
                    <h2>Attribution</h2>
                    <p>
                        This policy was inspired on <u><UnstyledLink to="https://matomo.org/privacy-policy/">Matomo privacy policy</UnstyledLink></u> under the license <u><UnstyledLink to="https://creativecommons.org/licenses/by/4.0/">CC BY</UnstyledLink></u>
                    </p>
                </PolicySection>
            </PolicyContentComponent>
            <OptOutPrivacyBanner/>
            <FooterSpace/>
        </PrivacyComponent>
    );
}

export default Privacy;