import React, { useRef, useEffect } from "react";
import styled from 'styled-components';


const StyledCircleLegend = styled.div`
    height: 10vh;
    width: 50wv;
    text-align: center;
    background: #FFFBFC;
    font-size: 1rem;
`;


function CircleLegend(props) {
    const details = useRef();
    useEffect(() => {
        if ((props.data.co2 === undefined)) return;
        if (props.data.runningDelta === null) {
            details.current.innerHTML = `<b style="color:#949292;">Idle</b> the air chamber is not sealed.`;
        }
        else if (props.data.co2.emissionRate === "0.0") {
            details.current.innerHTML = `
                <b style="color:#A8C256;">Absorption rate</b> is the decrease in CO<sub>2</sub> since the chamber was sealed.`;
        }
        else if (props.data.co2.absorptionRate === "0.0") {
            details.current.innerHTML = `
                <b style="color:#F65E5E;">Emission rate</b> is the increase in CO<sub>2</sub> since the chamber was sealed.`;
        }
        else {
            details.current.innerHTML = ``;
        }
    }, [props.data.co2, props.data.runningDelta]);
    return (
        <StyledCircleLegend >
                <div ref={details} />
        </StyledCircleLegend>
    );
}

export default CircleLegend;