import React from 'react';
import Form from '../../components/form/Form';
import styled from 'styled-components';
import FloatingBackground from "../../components/background/FloatingBackground";
import UnstyledLink from '../../components/utils/UnstyledLink';


const SubscriptionComponent = styled.div`
    height: 100vh;
    background: #373737;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    align-content: space-around;
`;

const TitleComponent = styled.div`
    text-align: center;
    font-size: clamp(1rem, 3vw + 0.5rem, 5vh);
    color: #FFFBFC;
`;

const FormStyleComponent = styled.div`
    padding-bottom: 5vh;
    padding-right: 2vw;
    padding-left: 2vw;
    width: clamp(min(700px, 90vw), 60vw, 1000px);
    margin-top: 0vh;
    margin-right: auto;
    margin-left: auto;
    height: 40vh;
`;

const SubscriptionMessageStyled = styled.div`
    color: #FFFBFC;
    padding-top: 30vh;
    font-size: clamp(1rem, 3vw + 0.5rem, 5vh);
`;

function renderSuccessfulSubscription() {
    return (
        <SubscriptionMessageStyled>
            <h4>Thank you for subscribing!</h4>
            <h6>
                Get in touch <u><UnstyledLink to='mailto:info@blugreen.cloud'>info@blugreen.cloud</UnstyledLink></u>
            </h6>
        </SubscriptionMessageStyled>
    )
  }

function renderTitleComponent(){
    return (
        <TitleComponent>
            <h3>Get in touch</h3>
            <h5>Subscribe to our mailing list</h5>
        </TitleComponent>
    )
}


function Subscription() {
    return (
        <SubscriptionComponent>
            <FloatingBackground>
                    <FormStyleComponent>
                        <Form
                            project="blugreencloud"
                            formColor="#FFFBFC"
                            errorColor="#F65E5E"
                            subscriptionMessage={renderSuccessfulSubscription()}
                            titleComponent={renderTitleComponent()}
                        />
                    </FormStyleComponent>
            </FloatingBackground>
        </SubscriptionComponent>
    );
}

export default Subscription;