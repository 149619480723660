import React from 'react';
import styled from 'styled-components';

import BluGreenCloudScheme from '../../images/algae/spiruli7.png';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import ScrollAnimation from '../../components/ScrollAnimation';
import { Element } from 'react-scroll';


const ServiceComponent = styled.div`
    min-height: 100vh;
    background: #a8c256;
    padding: 1px 0;
`;

const TitleComponent = styled.div`
    text-align: center;
    font-size: clamp(1rem, 3vw + 0.5rem, 5vh);
    --font-size: 2rem;
    color: #373737;
    height: 10vh;
`;

const ServiceInnerContentComponent = styled.div`
    min-height: 70vh;
    margin-top: 5vh;
    width: 80vw;
    margin-left: 10vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: space-around;
`;

const ServiceTextContentComponent = styled.div`
    max-width: min(531px, 100%);
    text-align: justify;
    margin-bottom: 5vh;
    font-size: clamp(0.5rem, 3vw + 0.5rem, 1.5rem);
`;

const ServiceMediaContentComponent = styled.div`
    width: min(27rem, 100%);
    margin-bottom: 5vh;
    background: #A8C256;
    padding 4vmin;
`;


function Service() {
    return (
        <Element name="serviceComponent">
            <ServiceComponent>
                <ScrollAnimation
                        duration={1000}
                        dataAOS={"fade-up"}
                    >
                <TitleComponent>
                    <h2>Meet our bioreactor</h2>
                </TitleComponent>
                    <ServiceInnerContentComponent>
                        <ServiceTextContentComponent>
                            At BluGreenCloud we developed a bioreactor to grow Spirulina Platensis. It's designed to give you a real-time view of carbon dioxide absorption and emission. The reactor contains a CO2 sensor in its air chamber, an internal pump system that allows the air to pass through the microalgae and an external air pump to get the fresh air needed to keep the microalgae alive.
                        </ServiceTextContentComponent>
                        <ServiceMediaContentComponent>
                        <Card sx={
                                { 
                                    background: "#FFFBFC",
                                    boxShadow: "inset 0 0 2vmin 2vmin #A8C256",
                                }}>
                                <CardMedia
                                    component="img"
                                    image={BluGreenCloudScheme}
                                />
                        </Card>
                        </ServiceMediaContentComponent>
                    </ServiceInnerContentComponent>
                </ScrollAnimation>
            </ServiceComponent>
        </Element>
    );
}

export default Service;