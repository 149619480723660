import React from 'react';
import { Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function ButtonStyled(props) {
  const { type, text, color } = props;

  const theme = createTheme({
    palette: {
      primary: {
        main: color || '#F65E5E',
        darker: color || '#F65E5E',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Button
        type={type}
        variant="contained"
        size="large"
        color="primary"
        disableElevation
        fontSize="inherit"
      >
        <b style={{ fontFamily: 'Space Mono, monospace' }}>{text}</b>
      </Button>
    </ThemeProvider>
  );
}

export default ButtonStyled;
