import React from 'react';
import styled from 'styled-components';
//import Form from '../../components/form/Form';
import SubscriptionForm from '../../components/subscriptionForm/SubscriptionForm';

const SubscriptionStyleWrapper = styled.div`
    background: white;
`;

const SubscriptionStyle = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5vh 15vw 20vh;
    @media (min-width: 768px) {
        flex-direction: row;
    }
`;

const MessageContainer = styled.div`
    padding: 4vh 4vw;
    width: 30vw;
    @media (max-width: 768px) {
        width: 60vw;
    }
`;

const Title = styled.h2`
    text-align: center;
    font-size: clamp(1rem, 3vw + 0.5rem, 4vh);
`;

const Subtitle = styled.p`
    text-align: center;
    font-size: clamp(1rem, 1.5vw + 0.5rem, 2.5vh);
`;

const FormStyle = styled.div`
    background-color: #C6ECEE;
    box-shadow: 0.5vw 0.5vh 1vw rgba(0, 0, 0, 0.2);
    width: 50%;
    height: 100%;
    padding: 2vh 2vw;
    @media (max-width: 768px) {        
        width: 60vw;
    }
`;

function SubscriptionMessage () {
    return (
        <MessageContainer>
            <Title>Be in Charge!</Title>
            <Subtitle>Subscribe and get a carbon capture game experience for free.</Subtitle>
        </MessageContainer>
    ); 
}


function Subscription() {
    return (
        <SubscriptionStyleWrapper>
            <SubscriptionStyle>
                <SubscriptionMessage />
                <FormStyle>
                    <SubscriptionForm
                        project="blugreencloud"
                        formColor="#000000"
                        errorColor="#F65E5E"
                    />
                </FormStyle>                
            </SubscriptionStyle>
        </SubscriptionStyleWrapper>
    );
}

export default Subscription;
