import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { validate } from 'react-email-validator';
import ButtonStyled from '../buttons/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import axios from 'axios';
import getCheckBox from './CheckBox';
import getCustomTextField from './InputField';


const BoxStyled = styled(Box) ({
  'marginTop': '5vh',
  'textAlign': 'center',
  'fontSize': '1.7rem',
  'fontFamily': 'Space Mono, monospace',
  '& .MuiTextField-root': { width: '50%', margin: '5%'},
  'display': 'flex',
  'flexDirection': 'column',
  'justifyContent': 'space-around',
  'height': '100%'
});

const SubscriptionMessageStyled = styled(Box)({
  'textAlign': 'center',
})

function getSubmitButton() {
  return (
    <ButtonStyled
      type="submit"
      text="Subscribe"
      color="#F65E5E"/>
  )
}

function renderFormBox(
  setEmail,
  handleSubmit,
  emailError,
  termAgreement,
  setTermAgreement,
  termError,
  formColor,
  errorColor,
  titleComponent
) {
  return (
    <div>
      {titleComponent}
      <BoxStyled
        component='form'
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        >
          <div>
            { getCustomTextField(setEmail, emailError, formColor, errorColor) }
          </div>
          <div>
            { getCheckBox(termAgreement, setTermAgreement, termError, formColor, errorColor) }
          </div>
          <div>
            { getSubmitButton() }
          </div>
    </BoxStyled>
    </div>
  );
}


function Form(props) {
    const project = props.project
    const subscribedTag = project + '-subscribed'
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [termAgreement, setTermAgreement] = useState(false)
    const [termError, setTermError] = useState(false)
    const navigate = useNavigate()
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!termAgreement) {
          setTermError(true)
        }
        else if (!validate(email)) {
          setEmailError(true)
          setTermError(false)
        }
        else {
          setTermError(false)
          setEmailError(false)
          axios.post(
            'https://kimon.blugreen.cloud/algae/subscribe',
            {
              email,
              project
            }
          )
          .then( response => {
            console.log(response)
          })
          .catch(error => {
            console.log(error)
          })
          sessionStorage.setItem(subscribedTag, true)
          navigate("/" + project)
        }
    }
    if(sessionStorage.getItem(subscribedTag) === 'true'){
      return (
        <SubscriptionMessageStyled>
          {props.subscriptionMessage}
        </SubscriptionMessageStyled>        
      )
    }
    return renderFormBox(
      setEmail,
      handleSubmit,
      emailError,
      termAgreement,
      setTermAgreement,
      termError,
      props.formColor,
      props.errorColor,
      props.titleComponent
    )
}

export default Form;