import React from "react";
import styled from 'styled-components';
import FloatingBackground from "../../components/background/FloatingBackground";
import ActionButton from "../../components/selfstyled/buttons/ActionButton";


const ConfirmationComponent = styled.div`
    height: 100vh;
    background: linear-gradient(to bottom, #80CED7 20%, #80CED7 80%, #a8c256 95%);
    font-family: Space Mono, monospace;
`;

const ContentComponent = styled.div`
    padding-top: 30vh;
    height: 40vh;
    padding-bottom: 15vh;
    text-align: center;
    font-size: clamp(1rem, 3vw + 0.5rem, 5vh);
    color: #FFFBFC;
    line-height: 10vh;
`; //#FFFBFC #373737

function BaseSuccessPage(props) {
    return (
        <ConfirmationComponent>
            <FloatingBackground>
                <ContentComponent>
                    <p>
                        {props.message}
                    </p>
                    <p>
                        <ActionButton
                            text="Home" onClick={() => window.location.href = "/"}
                            backgroundColor="#FFFBFC"
                            textColor="#80CED7"
                            hoverBackgroundColor="#263d40"
                            hoverTextColor="#FFFBFC"
                        />
                    </p>
                </ContentComponent>
            </FloatingBackground>
        </ConfirmationComponent>
    );
}

export default BaseSuccessPage;