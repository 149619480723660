import React from "react";
import styled from "styled-components";
import { useParams } from 'react-router-dom';
import ClockForm from "./ClockForm";
import ArrowHeader from "../../components/headers/ArrowHeader";

const ControlCentreComponent = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #fffbfc;
  font-family: Space Mono, monospace;
`;

const BodyComponent = styled.div`
  height: 90vh;
  width: 100vw;
`;


function ControlCentre() {
  const { date } = useParams()
  const rightPath = `/experience/control-center/${date}/stats`;
  const leftPath = `/experience/control-center/${date}/live`;
  return (
    <ControlCentreComponent>
        <ArrowHeader
          rightPath={rightPath}
          leftPath={leftPath}
          title="Control Center"
        />
      <BodyComponent>
        <ClockForm date={date} />
      </BodyComponent>
    </ControlCentreComponent>
  );
}

export default ControlCentre;
