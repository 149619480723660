import React from 'react';
import styled from 'styled-components';
import QuestionAnswer from "../../components/faq/QuestionAnswer";

const FAQWrapperStyle = styled.div`
    background: white;
`;

const FAQTitle = styled.h1`
    text-align: center;
    font-size: clamp(1rem, 3vw + 0.5rem, 4vh);
`;

const FAQStyle = styled.div`
    width: 80vw;
    margin: 5vh 10vw 10vh;
    padding: 5vh 5vw;
    box-sizing: border-box;
    overflow-y: auto;

`;


function FAQ() {
    return (
        <FAQWrapperStyle>
            <FAQTitle>Frequently Asked Questions</FAQTitle>
            <FAQStyle>
                <QuestionAnswer 
                    question="How is Carbon being capture?" 
                    answer="Cyanobacteria, also known as blue green algae, in a one liter bioreactor." 
                />
                <QuestionAnswer 
                    question="Is the carbon captured being stored?" 
                    answer="No. Since this is still a prototype, we focused on providing an interactive way to capture carbon. Although, we aim to bring the storing process to this experience in the future." 
                />
                <QuestionAnswer 
                    question="Can I see the game?" 
                    answer="<More details about the game here>" 
                />
                <QuestionAnswer 
                    question="Can I see recent stats about the bioreactor?" 
                    answer="<Provide chart or link here>" 
                />
            </FAQStyle>
        </FAQWrapperStyle>
    );
}

export default FAQ;
