import React, { useRef, useEffect }  from "react";
import * as Plot from "@observablehq/plot";


function LineChart(props){
    const stackRef = useRef();
    useEffect(() => {
      if (
        props.averages.dataPoints === undefined ||
        props.rounds.sealed === undefined ||
        props.rounds.pump === undefined ||
        props.rounds.idle === undefined ||
        props.rounds.calibration === undefined
      ) return;
      const chart = Plot.plot({
        x: { type: "utc", label: "UTC Time"},
        y: {
          grid: true,
          label: "CO2 ppm"
        },
        marks: [
          Plot.lineY(
            props.averages.dataPoints,
            {
              x: "epoch",
              y: "value",
              curve: "step"
            }
          ),
          Plot.ruleY([0]),
          Plot.barX(
            props.rounds.sealed,
          {
            x1: "start",
            x2: "end",
            fill: "#80CED7",
            fillOpacity: 0.3
          }
          ),
          Plot.barX(
            props.rounds.pump,
          {
            x1: "start",
            x2: "end",
            fill: "#A8C256",
            fillOpacity: 0.4
          }
          ),
          Plot.barX(
            props.rounds.idle,
          {
            x1: "start",
            x2: "end",
            fill: "#373737",
            fillOpacity: 0.4
          }
          ),
          Plot.barX(
            props.rounds.calibration,
          {
            x1: "start",
            x2: "end",
            fill: "#F65E5E",
            fillOpacity: 0.4
          }
          )
          //Plot.ruleY([400])
        ],
        width: window.innerWidth,
        height: 2*(window.innerHeight/3),
        //height: window.innerWidth,// 2*(window.innerHeight/3),
        color: {
          type: "linear",
          domain: [400, 10000],
          range: ["#A8C256", "#F65E5E"]
        }
      });
      const legendBars = Plot.legend(
        {
          color: {
            domain: ["Sealed chamber", "Air renewal", "Idle", "Maintenance"],
            range: ["#D9F0F3", "#DCE7BB", "#AFAFAF", "#FBBFBF"],
            label: "Round type"},
        }
      );
      stackRef.current.append(legendBars);
      stackRef.current.append(chart);
      return () => {
        chart.remove();
        legendBars.remove();
      };
    }, [props.averages.dataPoints, props.rounds]);
  
    return (
      <div ref={stackRef}/>
    );
  }

export default LineChart;