import React from 'react';
import styled from 'styled-components';
import Cards from "../../components/proof/Card";



const ReviewStyle = styled.div`
    width: 100vw;
    background: white;
`;


function Reviews() {
    return (
        <ReviewStyle>
            <Cards reviews={[
                { text: "It's great to have the power to caputer carbon from the comfort of my home.", author: "John Doe" },
                { text: "The power of microalgae. The real biopunk dream!", author: "Jennifer Orlando" },
            ]} />
        </ReviewStyle>
    );
}

export default Reviews;
