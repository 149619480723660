import React from "react";
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import ArrowHeader from "../../components/headers/ArrowHeader";
import useDataHook from '../../components/data/DataHook';
import LineChart from '../../components/data/LineChart';


const ControlCentreComponent = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #FFFBFC;
    font-family: Space Mono, monospace;
`;

const ChartCanvas = styled.div`
    height: 50vh;
    width: 90vw;
    margin-top: 10vh;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 25vh;
`;

const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  font-size: 1.5em;
  color: #373737;
`;

function ControlCentreStats() {
    const { date } = useParams()
    const rightPath = `/experience/control-center/${date}/live`;
    const leftPath = `/experience/control-center/${date}`;
    let averagesURL = `https://kimon.blugreen.cloud/algae/in-chamber-co2-averages/${date}`;
    let roundsURL = `https://kimon.blugreen.cloud/algae/rounds/${date}`;
    var averages = useDataHook(averagesURL, 120000)
    var rounds = useDataHook(roundsURL, 120000)
    const displayData = rounds && rounds.total !== 0;
    console.log(date)
    return (
        <ControlCentreComponent>
            <ArrowHeader
                rightPath={rightPath}
                leftPath={leftPath}
                title="Recent stats"
            />
            {displayData ? (
            <ChartCanvas>
                <LineChart 
                averages={averages}
                rounds={rounds}
                />
            </ChartCanvas>
            ) : (
            <ErrorMessage>There's no data for {date} yet.</ErrorMessage>
            )}
        </ControlCentreComponent>
    );
}

export default ControlCentreStats;
