import React from "react";
import styled from 'styled-components';
import PageTitle from "../../components/PageSEO";

import Presentation from "./Presentation";
import Service from "./Service";
import BluGreenCloudNow from "./BluGreenCloudNow";
import BluGreenCloudChart from "./BluGreenCloudChart";
import Value from "./Value";
import Subscription from "./Subscription";

const LandingPageComponent = styled.div`
    width: 100vw;
    display: flex;
    flex-direction: column;
    font-size: clamp(0.5rem, 2vw + 0.5rem, 1.7rem);
    font-family: Space Mono, monospace;
`;

function Algae() {
    return (
        <LandingPageComponent>
            <PageTitle
                title="BluGreenCloud"
                description="Carbon can become visible. Check our algae bioreactor in real-time."
                canonical="/algae"
            />
            <Presentation/>
            <Service/>
            <BluGreenCloudChart/>
            <BluGreenCloudNow/>
            <Value/>
            <Subscription/>
        </LandingPageComponent>
    );
}

export default Algae;
