import React from 'react';
import { Helmet } from "react-helmet-async"


function PageSEO(props) {
    return (
        <Helmet>
            <title>{ props.title }</title>
            <meta name="description" content={ props.description }/>
            <link rel="canonical" href={ props.canonical } />
        </Helmet>
    );
}

export default PageSEO;